<template>
  <div id="nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="index.remark" :name="index.webname" v-for="(index,key) in rules" :key="key">
        <template>
          <keep-alive>
            <componet :is="activeName"></componet>
          </keep-alive>
        </template> 
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

</el-tab-pane>
</el-tabs>
</div>
</template>
<script>
  //import ax from "@/js/ax.js";

  import Vue from 'vue'
  export default {
    data() {
      return {
        rules: [],
        activeName: "hello11",
      }
    },
    components: {

    },
    mounted() {
            console.log("1")
      let _this = this;
      console.log(this.ax)
      if (this.ax.model.type == "dev") {
           this.rules = [{
            webname:"hello11",
            component:"/test/Hello1.vue",
            "remark":'测试1'
          },
          {
            webname:"hello12",
            component:"/test/Hello2.vue",
            "remark":'测试2'
          }
        ]
        _this.createcomponent(_this.rules);  
      }else{
          ax.base.post("/coreSystem/login/geturl", {
          "name": "",
          "code": "1001"
        }).then(function (data) {
          _this.rules = data.data;
          _this.activeName = _this.rules[0].webname
          _this.createcomponent(_this.rules);
        }).catch(function (err) {
          alert(JSON.stringify(err.response.data));
        })

      }
     
    },
    methods: {
      createcomponent(rules) {
        for (let rule of rules) {
          Vue.component(rule.webname,()=>import('@/views' + rule.component))
        }
      },
      handleClick(tab, event) {
      
      }
    }
  }
</script>